

interface statusImplantacaoObjInterface {
  solicitacao: [string | null | any],
  cadastro: [string | null | any],
  aguardandoAutomacao: [string | null | any, string | null | any],
  aguardandoTablet: [string | null | any, string | null | any],
  setup: [string | null | any, string | null | any],
  aguardandoTreinamento: [string | null | any]
}

import { defineComponent, onBeforeMount, Ref, ref, watch } from "vue";
import Api from "../../services/Api";
import ModalTicketsImplantacao from "./components/ModalTicketsImplantacao.vue"
import useAlert from "../../composables/Alert";
import StatusCard from './components/StatusCard.vue'
import useEmitter from '../../composables/Emmiter';
import Title from "@/components/Title.vue"
import { useRoute } from "vue-router";
import { Modal } from "bootstrap";

export default defineComponent({
  name: "AcompanhamentoImplantacao",
  components: {Title, StatusCard, ModalTicketsImplantacao},
  emits: [],
  props: {},

  setup(props) {

    let auxModal;
    const { showTimeAlert } = useAlert();
    const count:Ref<number | any> = ref(0)
    const sendModal:Ref<any> = ref(null)
    const loading = ref(false)
    const route = useRoute()
    const emitter = useEmitter()
    const cnpj:Ref<string | null> = ref(null)
    const nomeFantasia:Ref<string | null> = ref(null)
    const statusImplantacaoObj: Ref<statusImplantacaoObjInterface> = ref({
        solicitacao: [null],
        cadastro: [null],
        aguardandoAutomacao: [null, null],
        aguardandoTablet: [null, null],
        setup: [null, null],
        aguardandoTreinamento: [null]
    })

    const openModal = id => {
      const auxElement = document.querySelector(`#${id}`);
      auxModal = new Modal(auxElement);
      auxModal.show();
    };
    
    async function getStatus(){
        try {
            loading.value = true
            const res = await Api.get(`getImplantacaoStatus?cnpj=${cnpj.value && cnpj.value.replace(/[^\d]/g, "")}`)
            if(res.error){
                statusImplantacaoObj.value.solicitacao = [null]
                loading.value = false
                return showTimeAlert(res.message, 'error'); 
            }
            sendModal.value = res.data.implantacao_status.data
            nomeFantasia.value = res.data.implantacao_status.data.store_name
            const ticketForm = await JSON.parse(res.data.implantacao_status.data.ticket_json || null)
            statusImplantacaoObj.value = {
                solicitacao: [res.data.implantacao_status.data.created_at || null],
                cadastro: [res.data.data_criacao || null],
                aguardandoAutomacao: [ ticketForm && ticketForm.campos.find((item)=>item.label.includes('Solicitação da base de produtos')).value,  ticketForm && ticketForm.campos.find((item)=>item.label.includes('Recebimento da base de produtos')).value],
                aguardandoTablet: [ ticketForm && ticketForm.campos.find((item)=>item.label.includes('Solicitação do tablet')).value,  ticketForm && ticketForm.campos.find((item)=>item.label.includes('Chegada do tablet')).value],
                setup: [ ticketForm && ticketForm.campos.find((item)=>item.label.includes('Solicitação da base de produtos')).value,  ticketForm && ticketForm.campos.find((item)=>item.label.includes('Data do treinamento')).value],
                aguardandoTreinamento: [ ticketForm && ticketForm.campos.find((item)=>item.label.includes('Data do treinamento')).value]
            }
            loading.value = false
        } catch (error) {
            console.log(error)
        }
    }

    onBeforeMount(() => {
        cnpj.value = route.query.cnpj as any || null as any;
        getStatus();
    });

    return { cnpj, getStatus, statusImplantacaoObj, loading, nomeFantasia, openModal, count, sendModal};
  },
});
