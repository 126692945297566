import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3ac81370"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "texto"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("button", {
    class: _normalizeClass(`btn ${_ctx.type} d-flex flex-row gap-3 px-5 py-3 justify-content-center align-items-center`)
  }, [
    _renderSlot(_ctx.$slots, "default", {}, undefined, true),
    (_ctx.text)
      ? (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(_ctx.text), 1))
      : _createCommentVNode("", true)
  ], 2))
}