

import { defineComponent, Ref, ref, watch } from "vue";
import moment from 'moment'
import Title from "../../../components/Title.vue"
import { boolean } from "yup";

export default defineComponent({
  name: "AcompanhamentoImplantacao",
  components: {Title},
  emits: [],
  props: {nameStatus: String, dataInicio: String, passo: Number, dataFim: String, status: Boolean},

  setup(props) {
    function dataTemplate(data: any) {
      if(!data) return '';
      return moment(data).format('DD/MM/YYYY');
    } 
    return { dataTemplate };
  },
});
